import React from "react";

import ReferralForm from "../../Components/ReferralForm/ReferralForm";

const Referral = () => {
  return (
    <>
      <ReferralForm />
    </>
  );
};

export default Referral;
