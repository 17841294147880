import React, { useEffect, useState, useContext } from "react";
import { Formik, Form } from "formik";
import axios from "axios";

import styles from "./FormComp.module.css";
import Modal from "../UI/Modal/Modal";

import Loader from "../../Assets/LoadingAnimation.gif";

import { ProgressContext } from "../../Context/ProgressContext";

function UploadDocs() {
  const [progress] = useContext(ProgressContext);
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setModalText] = useState("");
  const [uploadedFileLinks, setUploadedFileLinks] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
  });

  // console.log(progress);
  useEffect(() => {
    setAreButtonsDisabled(progress.upload_documents);
  }, [progress.upload_documents]);

  const handleFilesSubmit = async (val) => {
    let data = new FormData();
    for (let i = 0; i < Object.keys(val).length; i++) {
      data.append(`${Object.keys(val)[i]}`, Object.values(val)[i]);
    }
    data.append("application_id", localStorage.getItem("application_id"));
    data.append("email", localStorage.getItem("email"));
    console.log(data);
    setIsLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}api/v1/SS/uploadDocs`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    setIsLoading(false);
    console.log(res);
  };

  const handleValidation = (e, values) => {
    e.preventDefault();
    console.log(values);
    if (!values.first || !values.second || !values.third || !values.fourth || !values.fifth) {
      setModalHeading("Incomplete Files");
      setModalText("You must upload all the files before proceeding further");
      setShowModal(true);
      return;
    }
    handleFilesSubmit(values);
  };
  const modalHandler = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      setIsLoading(true);
      const res = await axios.get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }api/v1/SS/uploadDocs?application_id=${localStorage.getItem(
          "application_id"
        )}`
      );
      setIsLoading(false);
      console.log(res.data.result);
      const result = res.data.result;
      const obj = {
        first: result.first,
        second: result.second,
        third: result.third,
        fourth: result.fourth,
        fifth: result.fifth,
      };
      setUploadedFileLinks((prevState) => {
        return {
          ...obj,
        };
      });
    };
    fetchFiles();
  }, []);
  console.log(uploadedFileLinks);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "fit-content",
            zIndex: 3,
          }}
        >
          <img src={Loader} alt="loader" style={{}} />
        </div>
      ) : (
        <></>
      )}
      {showModal === true ? (
        <Modal
          modalHeading={modalHeading || "Error"}
          modalText={modalText || "Plear try again..."}
          modalHandler={modalHandler}
        />
      ) : (
        ""
      )}
      <Formik
        initialValues={{
          first: null,
          second: null,
          third: null,
          fourth: null,
          fifth: null
        }}
        // onSubmit={(val, actions) => handleFilesSubmit(val, actions)}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="form-group">
                <label htmlFor="file" className={styles.headingText}>
                  Upload Copies of your Current Driving License
                </label>

                <div>
                  <p className={styles.label}>*Profile Picture</p>
                  {!uploadedFileLinks.first ? (
                    <input
                      id="first"
                      name="first"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("first", event.currentTarget.files[0]);
                      }}
                    />
                  ) : (
                    <a
                      href={uploadedFileLinks.first}
                      className={styles.file_button}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  )}
                </div>

                <div>
                  <p className={styles.label}>*Copt of valid Drivers license with photo</p>
                  {!uploadedFileLinks.second ? (
                    <input
                      id="second"
                      name="second"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("second", event.currentTarget.files[0]);
                      }}
                    />
                  ) : (
                    <a
                      href={uploadedFileLinks.second}
                      className={styles.file_button}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  )}
                </div>

                <div>
                  <p className={styles.label}>*Drivers abstract request form</p>
                  {!uploadedFileLinks.third ? (
                    <input
                      id="third"
                      name="third"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("third", event.currentTarget.files[0]);
                      }}
                    />
                  ) : (
                    <a
                      href={uploadedFileLinks.third}
                      className={styles.file_button}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  )}
                </div>

                <div>
                  <p className={styles.label}>*Driver MDVR/CDVR</p>
                  {!uploadedFileLinks.fourth ? (
                    <input
                      id="fourth"
                      name="fourth"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("fourth", event.currentTarget.files[0]);
                      }}
                    />
                  ) : (
                    <a
                      href={uploadedFileLinks.fourth}
                      className={styles.file_button}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  )}
                </div>

                <div>
                  <p className={styles.label}>*Criminal Record check</p>
                  {!uploadedFileLinks.fifth ? (
                    <input
                      id="fifth"
                      name="fifth"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("fifth", event.currentTarget.files[0]);
                      }}
                    />
                  ) : (
                    <a
                      href={uploadedFileLinks.fifth}
                      className={styles.file_button}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  )}
                </div>
              </div>
              {/* Testing */}

              {!areButtonsDisabled && (
                <button
                  className={styles.button}
                  type="submit"
                  onClick={(e) => handleValidation(e, values)}
                  // disabled={areButtonsDisabled}
                >
                  Submit
                </button>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default UploadDocs;
