import React from "react";

import EmployeeVerificationFormComp from "../../Components/EmployeeVerificationFormComp/EmployeeVerificationFormComp";

function EmployeeVerification() {
  return (
    <div>
      <EmployeeVerificationFormComp />
    </div>
  );
}

export default EmployeeVerification;
