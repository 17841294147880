import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import cls from "./ReferralForm.module.css";

import logo from "../../Assets/LabourConnect-whitebg.svg";

import Loader from "../../Assets/LoadingAnimation.gif";

import Modal from "../UI/Modal/Modal";

// const PreviousEmployer = () => {
//   return (
//     <>
//       <section className={cls.previousEmployee}>
//         <div className={cls.prevEmployeeWrapper}>
//           <h1 className={`${cls.oneRem} ${cls.bold}`}>Previous employer</h1>
//           <a href="/#">Click to view details</a>
//         </div>
//         <div className={cls.prevEmployeeInfo}>
//           Lorem ipsum, dummy information about the preceptive employeer
//         </div>
//       </section>
//     </>
//   );
// };

const VehicleType = ({ id_name, name, callback, updateEquipmentClass }) => {
  const [isChecked, setIsChecked] = useState(false);

  const changeHandler = (e) => {
    setIsChecked(e.target.checked);
    updateEquipmentClass(e);
  };

  const [data, setData] = useState("");

  const milesChangeHandler = (e) => {
    setData(e.target.value);
  };

  useEffect(() => {
    // eslint-disable-next-line no-new-object
    let o = new Object();
    o[id_name] = data;
    callback(o);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className={cls.formGroup}>
        <table>
          <tr>
            <td>
              <input
                // required
                type="checkbox"
                onChange={changeHandler}
                name={name}
              />
            </td>
            <td>
              <label htmlFor={id_name}>{name}</label>
            </td>
          </tr>
          {isChecked && (
            <tr>
              <td></td>
              <td>
                <input
                  className={cls.textBox}
                  type="text"
                  placeholder="Approximate miles driven"
                  value={data}
                  name={id_name}
                  onChange={milesChangeHandler}
                />
              </td>
            </tr>
          )}
        </table>
      </div>
    </>
  );
};

const ReferralForm = () => {
  const location = useLocation();
  let [application_id, driver_id, driver_email, driverName] = location.search
    .split("?")[1]
    .split("&");

  application_id = application_id.split("=")[1];
  // eslint-disable-next-line no-unused-vars
  driver_id = driver_id.split("=")[1];
  // eslint-disable-next-line no-unused-vars
  driver_email = driver_email.split("=")[1];
  driverName = driverName.split("=")[1];

  // console.log(application_id, driver_id, driver_email, driverName);

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}api/v1/SS/refferal`, data)
      .then((res) => {
        console.log(res);
        if (res.data.success === false) {
          setIsModelOpen(true);
          setModalData({
            modalHeading: "There's been an error...",
            modalText: res.data.msg,
          });
        }
        if (res.data.success === true) {
          setIsModelOpen(true);
          setModalData({
            modalHeading: "Review Submitted!!!",
            modalText: res.data.msg,
          });
          setData({
            application_id: application_id,
            driver_id: driver_id,
            driver_name: driverName,
            license_number: "",
            perceptive_employer: "",
            previous_employer: "",
            reffering_person_name: "",
            reffering_person_phone: "",
            reffering_person_email: "",
            reffering_person_fax: "",
            employed_as: "",
            still_working: "No",
            start_date: "",
            end_date: "",
            reffering_person_position: "",
            reason_for_termination: "",
            rehired: "",
            additional: "",
            driver_motor_vehicle: "",
            class_of_equipment: [],
            b_train_miles: "",
            dump_truck_miles: "",
            lcv_miles: "",
            refrigerated_miles: "",
            super_bus_miles: "",
            tractor_semi_trailer_miles: "",
            tractor_trailer_miles: "",
            other_miles: "",
            decks_miles: "",
            flatbed_miles: "",
            motorcoach_miles: "",
            straight_truck_miles: "",
            tank_miles: "",
            tractor_and_two_trailer_miles: "",
            vans_miles: "",
            commercial_vehicle_exp: "",
            driver_region_exp: [],
            evaluation: {
              attitude: "",
              dependability: "",
              aptitude: "",
              client_courtesy: "",
              conflicts: "",
              compliance: "",
              communication: "",
              time_loss: "",
              follows_instruction: "",
              care_of_equipment: "",
              reliability: "",
              equipment_handling: "",
              log_book: "",
              equipment_cleanliness: "",
            },
            accident_check: "",
            suspensions: "",
            other_info: "",
            remarks: "",
            safety_sensitive: "",
          });
        }
      })
      .then(() => {
        setIsLoading(false);
        // console.log("2nd Then");
      })
      .catch((err) => console.log(err));
  };

  const [modalData, setModalData] = useState({
    modalHeading: "",
    modalText: "",
  });

  const [isModelOpen, setIsModelOpen] = useState(false);

  const modalHandler = () => {
    setIsModelOpen((prev) => !prev);
  };

  const [data, setData] = useState({
    application_id: application_id,
    driver_id: driver_id,
    driver_name: driverName,
    license_number: "",
    perceptive_employer: "",
    previous_employer: "",
    reffering_person_name: "",
    reffering_person_phone: "",
    reffering_person_email: "",
    reffering_person_fax: "",
    employed_as: "",
    still_working: "No",
    start_date: "",
    end_date: "",
    reffering_person_position: "",
    reason_for_termination: "",
    rehired: "",
    additional: "",
    driver_motor_vehicle: "",
    class_of_equipment: [],
    b_train_miles: "",
    dump_truck_miles: "",
    lcv_miles: "",
    refrigerated_miles: "",
    super_bus_miles: "",
    tractor_semi_trailer_miles: "",
    tractor_trailer_miles: "",
    other_miles: "",
    decks_miles: "",
    flatbed_miles: "",
    motorcoach_miles: "",
    straight_truck_miles: "",
    tank_miles: "",
    tractor_and_two_trailer_miles: "",
    vans_miles: "",
    commercial_vehicle_exp: "",
    driver_region_exp: [],
    evaluation: {
      attitude: "",
      dependability: "",
      aptitude: "",
      client_courtesy: "",
      conflicts: "",
      compliance: "",
      communication: "",
      time_loss: "",
      follows_instruction: "",
      care_of_equipment: "",
      reliability: "",
      equipment_handling: "",
      log_book: "",
      equipment_cleanliness: "",
    },
    accident_check: "",
    suspensions: "",
    other_info: "",
    remarks: "",
    safety_sensitive: "",
  });

  const changeHandler = (e) => {
    setData(() => {
      return {
        ...data,
        [e.target.name]: e.target.value,
      };
    });
  };

  const vehicleTypeUpdateHandler = (payload) => {
    // console.log(payload);
    setData(() => {
      return {
        ...data,
        ...payload,
      };
    });
  };

  const vehicleType = [
    {
      id_name: "b_train_miles",
      name: "B-Train",
    },
    {
      id_name: "dump_truck_miles",
      name: "Dump - Truck",
    },
    {
      id_name: "lcv_miles",
      name: "LCV",
    },
    {
      id_name: "refrigerated_miles",
      name: "Refrigerated",
    },
    {
      id_name: "super_bus_miles",
      name: "Super Bus",
    },
    {
      id_name: "tractor_semi_trailer_miles",
      name: "Tractor Semi Trailer",
    },
    {
      id_name: "tractor_trailer_miles",
      name: "Tractor - Trailer",
    },
    {
      id_name: "other_miles",
      name: "Other (specify)",
    },
    {
      id_name: "decks_miles",
      name: "Decks",
    },
    {
      id_name: "flatbed_miles",
      name: "Flat bed",
    },
    {
      id_name: "motorcoach_miles",
      name: "Motor coach",
    },
    {
      id_name: "straight_truck_miles",
      name: "Straight Truck",
    },
    {
      id_name: "tank_miles",
      name: "Tank",
    },
    {
      id_name: "tractor_and_two_trailer_miles",
      name: "Tractor and Two Trailer",
    },
    {
      id_name: "commercial_vehicle_exp",
      name: "Vans",
    },
  ];

  let [classOfEquipments, setClassOfEquipments] = useState([]);

  const updateEquipmentClass = (e) => {
    if (classOfEquipments.includes(e.target.name)) {
      setClassOfEquipments((prevState) => {
        return prevState.filter((item) => item !== e.target.name);
      });
    } else {
      setClassOfEquipments((prevState) => {
        return [...prevState, e.target.name];
      });
    }
  };

  useEffect(() => {
    setData(() => {
      return {
        ...data,
        class_of_equipment: classOfEquipments,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classOfEquipments]);

  const driverRegionExperiance = [
    {
      id_name: "local",
      name: "Local",
    },
    {
      id_name: "local_only",
      name: "Local Only",
    },
    {
      id_name: "canada",
      name: "Canada",
    },
    {
      id_name: "canada_only",
      name: "Canada Only",
    },
    {
      id_name: "mountains",
      name: "Mountains",
    },
    {
      id_name: "usa_western",
      name: "USA Western",
    },
    {
      id_name: "usa_southern",
      name: "USA Sothern",
    },
    {
      id_name: "usa_midwest",
      name: "USA Midwest",
    },
    {
      id_name: "usa_central",
      name: "USA Central",
    },
    {
      id_name: "usa_eastern",
      name: "USA Eastern",
    },
    {
      id_name: "usa_only",
      name: "USA Only",
    },
  ];

  let [driverRegionExperianceArr, setDriverRegionExperianceArr] = useState([]);

  useEffect(() => {
    setData(() => {
      return {
        ...data,
        driver_region_exp: driverRegionExperianceArr,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverRegionExperianceArr]);

  const evaluationType = [
    {
      id_name: "attitude",
      name: "Attitude",
    },
    {
      id_name: "dependability",
      name: "Dependability",
    },
    {
      id_name: "aptitude",
      name: "Aptitude",
    },
    {
      id_name: "client_courtesy",
      name: "Client Courtesy",
    },
    {
      id_name: "conflicts",
      name: "Conflicts",
    },
    {
      id_name: "compliance",
      name: "Compliance",
    },
    {
      id_name: "communication",
      name: "Communication",
    },
    {
      id_name: "time_loss",
      name: "Time Loss",
    },
    {
      id_name: "follows_instruction",
      name: "Follows Instructions",
    },
    {
      id_name: "care_of_equipment",
      name: "Takes care of Assigned Equipment",
    },
    {
      id_name: "reliability",
      name: "Reliablity",
    },
    {
      id_name: "equipment_handling",
      name: "Equipment Handling",
    },
    {
      id_name: "log_book",
      name: "Logbook",
    },
    {
      id_name: "equipment_cleanliness",
      name: "Equipment Cleanliness",
    },
  ];

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "fit-content",
            zIndex: 3,
          }}
        >
          <img src={Loader} alt="loader" style={{}} />
        </div>
      ) : (
        <></>
      )}

      {isModelOpen ? (
        <Modal
          modalHeading={modalData.modalHeading}
          modalText={modalData.modalText}
          modalHandler={modalHandler}
        />
      ) : (
        <></>
      )}

      <section className={cls.container}>
        <div className={cls.logoContainer}>
          <img src={logo} alt="Logo" className={cls.logo} />
        </div>

        <h1 className={cls.heading} style={{ textAlign: "center" }}>
          Employee Verification Form
        </h1>

        <form className={cls.content} onSubmit={submitHandler}>
          <h2 className={cls.heading}>Please fill Employment details</h2>

          <div className={cls.driverDetails}>
            <table>
              <tr>
                <td className={cls.bold}>Driver Name</td>
                <td className={cls.bold}>License Number</td>
              </tr>

              <tr>
                <td>{driverName}</td>
                <td>
                  <input
                    required
                    type="text"
                    name="license_number"
                    id="license_number"
                    className={cls.textBox}
                    onChange={changeHandler}
                    value={data.license_number}
                  />
                </td>
              </tr>
            </table>
          </div>

          {/* <PreviousEmployer /> */}

          {/* <div className={`${cls.oneRem} ${cls.bold} ${cls.paddingDown}`}>
          Attention Gurvindar Singh
        </div> */}

          <div className={`${cls.flex}`}>
            <div
              style={{ marginTop: "auto", marginBottom: "auto" }}
              className={`${cls.oneRem} ${cls.bold}`}
            >
              Perceptive Employer
            </div>
            <div className={`${cls.leftIndent} ${cls.pointNineRem}`}>
              <input
                required
                type="text"
                name="perceptive_employer"
                id="perceptive_employer"
                className={cls.textBox}
                onChange={changeHandler}
                value={data.perceptive_employer}
              />
            </div>
          </div>

          <div className={`${cls.flex}`}>
            <div
              style={{ marginTop: "auto", marginBottom: "auto" }}
              className={`${cls.oneRem} ${cls.bold}`}
            >
              Previous Employer
            </div>
            <div className={`${cls.leftIndent} ${cls.pointNineRem}`}>
              <input
                required
                type="text"
                name="previous_employer"
                id="previous_employer"
                className={cls.textBox}
                onChange={changeHandler}
                value={data.previous_employer}
              />
            </div>
          </div>

          <div className={`${cls.flex}`}>
            <div
              style={{ marginTop: "auto", marginBottom: "auto" }}
              className={`${cls.oneRem} ${cls.bold}`}
            >
              Phone Number
            </div>
            <div className={`${cls.leftIndent} ${cls.pointNineRem}`}>
              <input
                required
                type="text"
                name="reffering_person_phone"
                id="reffering_person_phone"
                className={cls.textBox}
                onChange={changeHandler}
                value={data.reffering_person_phone}
              />
            </div>
          </div>

          <div className={`${cls.flex}`}>
            <div
              style={{ marginTop: "auto", marginBottom: "auto" }}
              className={`${cls.oneRem} ${cls.bold}`}
            >
              Email ID
            </div>
            <div className={`${cls.leftIndent} ${cls.pointNineRem}`}>
              <input
                required
                type="text"
                name="reffering_person_email"
                id="reffering_person_email"
                className={cls.textBox}
                onChange={changeHandler}
                value={data.reffering_person_email}
              />
            </div>
          </div>

          <div className={`${cls.flex}`}>
            <div
              style={{ marginTop: "auto", marginBottom: "auto" }}
              className={`${cls.oneRem} ${cls.bold}`}
            >
              Fax
            </div>
            <div className={`${cls.leftIndent} ${cls.pointNineRem}`}>
              <input
                required
                type="text"
                name="reffering_person_fax"
                id="reffering_person_fax"
                className={cls.textBox}
                onChange={changeHandler}
                value={data.reffering_person_fax}
              />
            </div>
          </div>

          {/* <div className={cls.formGroup}>
            Information supplied by Gurwinder Singh
            <span className={cls.leftIndent}>
              <input required type="radio" name="infoSupplied" id="infoSupplied_yes" />
              <label htmlFor="infoSupplied_yes">Yes</label>
            </span>
            <span className={cls.leftIndent}>
              <input required type="radio" name="infoSupplied" id="infoSupplied_no" />
              <label htmlFor="infoSupplied_no">No</label>
            </span>
          </div>

          <div className={cls.formGroup}>
            The applicant named above was employed by us
            <span className={cls.leftIndent}>
              <input required type="radio" name="employedByUs" id="emplyedByUs_yes" />
              <label htmlFor="emplyedByUs_yes">Yes</label>
            </span>
            <span className={cls.leftIndent}>
              <input required type="radio" name="employedByUs" id="emplyedByUs_no" />
              <label htmlFor="emplyedByUs_no">No</label>
            </span>
          </div> */}

          <div className={cls.formGroup}>
            Employed As
            <div>
              <input
                required
                type="text"
                className={cls.textBox}
                placeholder={"Ex. Driver "}
                name="employed_as"
                onChange={changeHandler}
                value={data.employed_as}
              />
            </div>
          </div>

          <div className={cls.formGroup}>
            Still Working?
            <span className={cls.leftIndent}>
              <input
                required
                type="radio"
                name="still_working"
                id="still_working_yes"
                value={"Yes"}
                onChange={changeHandler}
              />
              <label htmlFor="still_working_yes">Yes</label>
            </span>
            <span className={cls.leftIndent}>
              <input
                required
                type="radio"
                name="still_working"
                id="still_working_no"
                value={"No"}
                onChange={changeHandler}
              />
              <label htmlFor="still_working_no">No</label>
            </span>
          </div>

          <div className={cls.formGroup}>
            <table>
              <tr>
                <td>Start Date</td>
                <td>End Date</td>
              </tr>
              <tr>
                <td>
                  <input
                    required
                    type="date"
                    className={cls.textBox}
                    name="start_date"
                    value={data.start_date}
                    onChange={changeHandler}
                  />
                </td>
                <td>
                  <input
                    required
                    type="date"
                    className={cls.textBox}
                    name="end_date"
                    value={data.end_date}
                    onChange={changeHandler}
                  />
                </td>
              </tr>
            </table>
          </div>

          <div className={cls.formGroup}>
            Position
            <div>
              <input
                required
                type="text"
                className={cls.textBox}
                placeholder={"Enter your position"}
                name="reffering_person_position"
                value={data.reffering_person_position}
                onChange={changeHandler}
              />
            </div>
          </div>

          <div className={cls.formGroup}>
            Reason for Termination
            <div>
              <textarea
                className={cls.textArea}
                placeholder={"State your reasons"}
                rows="5"
                cols="40"
                name="reason_for_termination"
                value={data.reason_for_termination}
                onChange={changeHandler}
              />
            </div>
          </div>

          <div className={cls.formGroup}>
            Would you Rehired
            <div style={{ marginTop: "0.7rem" }}>
              <span>
                <input
                  required
                  type="radio"
                  name="rehired"
                  id="rehired_yes"
                  value={"Yes"}
                  onChange={changeHandler}
                />
                <label htmlFor="rehired_yes">Yes</label>
              </span>
              <span className={cls.leftIndent}>
                <input
                  required
                  type="radio"
                  name="rehired"
                  id="rehired_no"
                  value={"No"}
                  onChange={changeHandler}
                />
                <label htmlFor="rehired_no">No</label>
              </span>
              <span className={cls.leftIndent}>
                <input
                  required
                  type="radio"
                  name="rehired"
                  id="rehired_uponReview"
                  value={"Upon Review"}
                  onChange={changeHandler}
                />
                <label htmlFor="rehired_uponReview">Upon Review</label>
              </span>
              <span className={cls.leftIndent}>
                <input
                  required
                  type="radio"
                  name="rehired"
                  id="rehired_noCompanyPolicy"
                  value={"No Company Policy"}
                  onChange={changeHandler}
                />
                <label htmlFor="rehired_noCompanyPolicy">
                  No Company Policy
                </label>
              </span>
            </div>
          </div>

          <div className={cls.formGroup}>
            <table>
              <tr>
                <td>
                  Do you need to add additional Employment / <br /> Contracted
                  dates ?
                </td>
                <td>
                  <span className={cls.leftIndent}>
                    <input
                      required
                      type="radio"
                      name="additional"
                      id="additional_yes"
                      value={"Yes"}
                      onChange={changeHandler}
                    />
                    <label htmlFor="additional_yes">Yes</label>
                  </span>
                  <span className={cls.leftIndent}>
                    <input
                      required
                      type="radio"
                      name="additional"
                      id="additional_no"
                      value={"No"}
                      onChange={changeHandler}
                    />
                    <label htmlFor="additional_no">No</label>
                  </span>
                </td>
              </tr>
            </table>
          </div>

          <div className={cls.formGroup}>
            <table>
              <tr>
                <td>Did the applicant drive motor vehicle for you</td>
                <td>
                  <span className={cls.leftIndent}>
                    <input
                      required
                      type="radio"
                      name="driver_motor_vehicle"
                      id="driver_motor_vehicle_yes"
                      value={"Yes"}
                      onChange={changeHandler}
                    />
                    <label htmlFor="driver_motor_vehicle_yes">Yes</label>
                  </span>
                  <span className={cls.leftIndent}>
                    <input
                      required
                      type="radio"
                      name="driver_motor_vehicle"
                      id="driver_motor_vehicle_no"
                      value={"No"}
                      onChange={changeHandler}
                    />
                    <label htmlFor="driver_motor_vehicle_no">No</label>
                  </span>
                </td>
              </tr>
            </table>
          </div>

          <div className={cls.vehicleNDriverInfo}>
            <div className={cls.vNhLeftSide}>
              {vehicleType.map((item, index) => {
                return (
                  <>
                    <VehicleType
                      id_name={item.id_name}
                      name={item.name}
                      key={`${item.id_name}${index}`}
                      callback={vehicleTypeUpdateHandler}
                      updateEquipmentClass={updateEquipmentClass}
                    />
                  </>
                );
              })}
            </div>

            <div className={cls.vNhRightSide}>
              <h1 className={`${cls.oneRem} ${cls.heading}`}>
                Driver Experience Details
              </h1>

              <div className={cls.formGroup}>
                Has Driving Experience with Commercial Vehicles
                <div style={{ marginTop: "0.7rem" }}>
                  <span>
                    <input
                      required
                      type="radio"
                      name="commercial_vehicle_exp"
                      id="commercial_vehicle_exp_single"
                      value={"Single"}
                      onChange={changeHandler}
                    />
                    <label htmlFor="commercial_vehicle_exp_single">
                      Single
                    </label>
                  </span>
                  <span className={cls.leftIndent}>
                    <input
                      required
                      type="radio"
                      name="commercial_vehicle_exp"
                      id="commercial_vehicle_exp_team"
                      value={"Team"}
                      onChange={changeHandler}
                    />
                    <label htmlFor="commercial_vehicle_exp_team">Team</label>
                  </span>
                  <span className={cls.leftIndent}>
                    <input
                      required
                      type="radio"
                      name="commercial_vehicle_exp"
                      id="commercial_vehicle_exp_single&team"
                      value={"SingleAndTeam"}
                      onChange={changeHandler}
                    />
                    <label htmlFor="commercial_vehicle_exp_single&team">
                      Single & Team
                    </label>
                  </span>
                </div>
              </div>

              <div>
                <span>Driver Experience</span>
                <div className={cls.driverRegion}>
                  {driverRegionExperiance.map((item, index) => {
                    return (
                      <>
                        <span
                          key={`${item.id_name}${index}`}
                          style={{ paddingTop: "0.5rem" }}
                        >
                          <input
                            // required
                            type="checkbox"
                            name={item.id_name}
                            id={item.id_name}
                            value={item.id_name}
                            onChange={(e) => {
                              if (
                                driverRegionExperianceArr.includes(
                                  e.target.value
                                )
                              ) {
                                setDriverRegionExperianceArr((prevState) => {
                                  return prevState.filter(
                                    (item) => item !== e.target.value
                                  );
                                });
                                // console.log(driverRegionExperianceArr);
                              } else {
                                setDriverRegionExperianceArr((prevState) => {
                                  return [...prevState, e.target.value];
                                });
                                // console.log(driverRegionExperianceArr);
                              }
                            }}
                          />
                          <label htmlFor={item.id_name}>{item.name}</label>
                        </span>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={cls.formGroup}>
            <h1 className={`${cls.heading}`}>Evaluation</h1>
            <div>
              Please rate according to : <br /> U - Unsatisfactory <br /> S -
              Satisfactory <br /> G - Good <br /> E - Excellent
            </div>
            <table>
              <tr>
                <td>Evaluation Type</td>
                <td>U</td>
                <td>S</td>
                <td>G</td>
                <td>E</td>
              </tr>
              {evaluationType.map((item, index) => {
                return (
                  <>
                    <tr>
                      <td>{item.name}</td>
                      <td>
                        <input
                          // required
                          type="radio"
                          name={`${item.id_name}`}
                          value="U"
                          onChange={(e) => {
                            const subObject = data.evaluation;
                            setData(() => {
                              return {
                                ...data,
                                evaluation: {
                                  ...subObject,
                                  [e.target.name]: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          required
                          type="radio"
                          name={`${item.id_name}`}
                          value="S"
                          onChange={(e) => {
                            const subObject = data.evaluation;
                            setData(() => {
                              return {
                                ...data,
                                evaluation: {
                                  ...subObject,
                                  [e.target.name]: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          required
                          type="radio"
                          name={`${item.id_name}`}
                          value="G"
                          onChange={(e) => {
                            const subObject = data.evaluation;
                            setData(() => {
                              return {
                                ...data,
                                evaluation: {
                                  ...subObject,
                                  [e.target.name]: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          required
                          type="radio"
                          name={`${item.id_name}`}
                          value="E"
                          onChange={(e) => {
                            const subObject = data.evaluation;
                            setData(() => {
                              return {
                                ...data,
                                evaluation: {
                                  ...subObject,
                                  [e.target.name]: e.target.value,
                                },
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </table>
          </div>

          <div className={cls.formGroup}>
            <h1 className={`${cls.heading}`}>Accidents</h1>
            <div>
              Complete the following for any accidents included on your accident
              register ( ? 390 . (15b)) That Involved the applicant in the 3
              year prior to the application Date shown above Click here if there
              is no accident register data for the driver
            </div>
            <input
              required
              type="radio"
              name="accident_check"
              id="accident_check"
              value={"Agree"}
              onChange={changeHandler}
            />
            <label htmlFor="accident_check">Agree</label>
          </div>

          <div className={cls.formGroup}>
            <h1 className={`${cls.heading}`}>Suspension</h1>
            Have you been any suspensions
            <span className={cls.leftIndent}>
              <input
                required
                type="radio"
                name="suspensions"
                id="suspensions_yes"
                value={"Yes"}
                onChange={changeHandler}
              />
              <label htmlFor="suspensions_yes">Yes</label>
            </span>
            <span className={cls.leftIndent}>
              <input
                required
                type="radio"
                name="suspensions"
                id="suspensions_no"
                value={"No"}
                onChange={changeHandler}
              />
              <label htmlFor="suspensions_no">No</label>
            </span>
          </div>

          <div className={cls.formGroup}>
            Informations
            <div>
              <textarea
                className={cls.textArea}
                placeholder={"State your reasons"}
                rows="5"
                cols="40"
                name="other_info"
                value={data.other_info}
                onChange={changeHandler}
              />
            </div>
          </div>

          <div className={cls.formGroup}>
            Any other remarks
            <div>
              <textarea
                className={cls.textArea}
                placeholder={"Please mention any other remarks"}
                rows="5"
                cols="40"
                name="remarks"
                value={data.remarks}
                onChange={changeHandler}
              />
            </div>
          </div>

          <div className={cls.formGroup}>
            *Was job designated as a safety sensitive function in any DOT
            regulated mode subjects to drug and alcohol testing as required by
            49CFR Part 40?
            <span className={cls.leftIndent}>
              <input
                required
                type="radio"
                name="safety_sensitive"
                id="safety_sensitive_yes"
                value={"Yes"}
                onChange={changeHandler}
              />
              <label htmlFor="safety_sensitive_yes">Yes</label>
            </span>
            <span className={cls.leftIndent}>
              <input
                required
                type="radio"
                name="safety_sensitive"
                id="safety_sensitive_no"
                value={"No"}
                onChange={changeHandler}
              />
              <label htmlFor="safety_sensitive_no">No</label>
            </span>
          </div>
          <div className={cls.formGroup}>
            <input type="submit" />
          </div>
        </form>
      </section>
    </>
  );
};

export default ReferralForm;
