export const positions = [
  { none: "None" },
  { accountant: "Accountant" },
  { accountsPayableClerk: "Accounts Payable Clerk" },
  { accountsReceivableClerk: "Accounts Receivable Clerk" },
  { assemblyLineWorker: "Assembly Line Worker" },
  { cabinetMaker: "Cabinet Maker" },
  { categoryManager: "Category Manager" },
  { commercialDriverAz: "Commercial Driver AZ" },
  { commercialDriverDz: "Commercial Driver DZ" },
  { customerServiceManager: "Customer Service Manager" },
  { demandPlanner: "Demand Planner" },
  { distributionCenterManager: "Distribution Center Manager" },
  { fleetManager: "Fleet Manager" },
  { foodProcessingOperator: "Food Processing Operator" },
  { forkliftOperator: "Forklift Operator" },
  { hrAnalyst: "HR Analyst" },
  { hrBenefits: "HR Benefits" },
  { hrBusinessPartner: "HR Business Partner" },
  { hrCoordinator: "HR Coordinator" },
  { hrGeneralist: "HR Generalist" },
  { hrManager: "HR Manager" },
  { hrSpecialist: "HR Specialist" },
  { inventoryAnalyst: "Inventory Analyst" },
  { inventoryController: "Inventory Controller" },
  { inventoryManager: "Inventory Manager" },
  { janitor: "Janitor" },
  { logisticsAssistant: "Logistics Assistant" },
  { logisticsClerk: "Logistics Clerk" },
  { logisticsCoordinator: "Logistics Coordinator" },
  { logisticsManager: "Logistics Manager" },
  { logisticsPlanner: "Logistics Planner" },
  { logisticsSpecialist: "Logistics Specialist" },
  { lumper: "Lumper" },
  { machineOperator: "Machine Operator" },
  { maintenanceMechanic: "Maintenance Mechanic" },
  { materialHandler: "Material Handler" },
  { millright: "Millright" },
  { payrollClerck: "Payroll Clerck" },
  { plantOperator: "Plant Operator" },
  { procurementManager: "Procurement Manager" },
  { productionManager: "Production Manager" },
  { productionSupervisor: "Production Supervisor" },
  { productionWorker: "Production Worker" },
  { purchasingManager: "Purchasing Manager" },
  { qualityAssuranceManager: "Quality Assurance Manager" },
  { receiver: "Receiver" },
  { safetyManager: "Safety Manager" },
  { shipper: "Shipper" },
  { supplyChainManager: "Supply Chain Manager" },
  { trafficAndLogisticsManager: "Traffic and Logistics Manager" },
  { transportationManager: "Transportation Manager" },
  { warehosueAssistant: "Warehosue Assistant" },
  { warehouseAssociate: "Warehouse Associate" },
  { warehouseManager: "Warehouse Manager" },
  { warehouseOrderPicker: "Warehouse Order Picker" },
  { warehouseShiftSupervisor: "Warehouse Shift Supervisor" },
  { warehouseSupervisor: "Warehouse Supervisor" },
  { warehouseWorker: "Warehouse Worker" },
  { welder: "Welder" },
];
