import React, { useState, createContext } from "react";

export const ProgressContext = createContext();

const ProgressContextProvider = ({ children }) => {
  const [progress, setProgress] = useState({
    general_information: false,
    driver_information: false,
    employment_record: false,
    driver_experience: false,
    upload_documents: false,
    on_duty_hours: false,
    terms_and_conditions: false,
  });

  return (
    <ProgressContext.Provider value={[progress, setProgress]}>
      {children}
    </ProgressContext.Provider>
  );
};

export default ProgressContextProvider;
