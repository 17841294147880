import * as Yup from 'yup';

const generalInfoSchema = Yup.object().shape({
  position: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  dateOfBirth: Yup.string().required('Required'),
  totalMiles: Yup.string().required('Required'),
  bondRefusal: Yup.string().required('Required'),
  crossBorders: Yup.string().required('Required'),
});

const LineHaulDriverSchema = Yup.object().shape({
  socialInsuranceNumber: Yup.string().required('Required'),
  emergencyContactName: Yup.string().required('Required'),
  emergencyContactNumber: Yup.string().required('Required'),
  pay: Yup.string().required('Required'),
  legalRightToWorkInCanada: Yup.string().required('Required'),
  accidentRecord1: Yup.string().required('Required'),
});

const onDutyHoursSchema = Yup.object().shape({
  licenseNumber: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  classes: Yup.string().required('Required'),
  endorsment: Yup.string().required('Required'),
  restriction: Yup.string().required('Required'),
  lastRelievedDate: Yup.string().required('Required'),
  lastRelievedTime: Yup.string().required('Required'),
  typeOfLicense: Yup.string().required('Required'),
  issuingState: Yup.string().required('Required'),
  anotherEmployer: Yup.string().required('Required'),
  intendToWorkForanotherEmployer: Yup.string().required('Required'),
});

export { generalInfoSchema, LineHaulDriverSchema, onDutyHoursSchema };
